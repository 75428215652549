@import '../../../sass\ configs/00-complete.scss';

// optionItem,optionsPanel


.optionsPanel {
    width: 100%;
    background-color: white !important;
    


    @at-root .optionItem {
  

        &:not(:first-child){
            margin-top: 0.4em;
            padding-top: 0.4em;
            border-top: 1px solid rgba(0,0,0,0.3);
        }
    
        :global(.check-module__label){
            justify-content: space-between;
            align-items: stretch !important;
        }

        :global(.check-module__fakeInput){
            height: auto;
        }
    }
}