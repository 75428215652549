@import '../../sass\ configs/00-complete.scss';




.countryTalkList {
    font-size: 10px;
    width: 100%;

    @at-root .container {
        padding: 3em 4em 4em;
        margin: 0 auto;

        @at-root .filterSpot {
            :global(.filterE-module__filter){
                grid-template-areas:"createdRange confirmBtn";
                grid-template-columns: 1fr auto;

            }
        }

        @at-root .body {
            margin-top: 5em;

            :global(.tabs-module__tabList){
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
                list-style: none;
            
                :global(.tabs-module__tabItem){
                    // @include typo($font-family: 'Roboto',$font-size: 16px,$line-height: 24px,$color: #1890FF);
                    @include typo($font-family: 'Roboto',$font-size: 1.6em,$line-height: 1.5em,$color: rgba(0, 0, 0, 0.85));
                    padding-bottom: 0.71em;     //13.5px      0.71em
                    margin-bottom: -2px;
                    text-transform: capitalize;
                    margin-right: 1em;
            
                    &:not(:first-child){
                        margin-right: 1.68em;     //32px     1.68em
                    }
                }
                :global(.tabs-module__tabActive){
                    border-bottom: 2px solid #1890FF;
                    color: #1890FF;
                    
                }
            }
            :global(.tabs-module__tabsContentWrapper){
                margin-top: 3em;
                :global(.tabs-module__tabContent){
                    display: none;
                }
                :global(.tabs-module__contentActive){
                    display: block;
                }
             
            }
        }


        @at-root .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            @at-root .itemLabel {
                color: rgba(0, 0, 0, 0.4);
                margin-right: 2em;

            }
            
            @at-root .itemValue {
                height: 32px;
                border: 1px solid darken(#D9D9D9,10);
                border-radius: 2px;
                width: 300px;
                padding: 4px 12px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}
 @at-root .exportBtn * {
        text-decoration: none !important;
    }