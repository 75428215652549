
button {
    display: none;
}
// .chartsContainer {
//   display: flex;
//   gap: 3rem;
//   flex-direction: column;
//   border: 1px solid black;
// }
// .chartBox {
//   width: 50%;
//    border: 1px solid red;
// }
.chartsContainer {
  display: flex;
  flex-wrap: wrap;
}

.chartBox {
  width: calc(50% - 20px); /* Adjust width as needed */
  margin: 10px 5px;
}