@import '../../sass\ configs/00-complete.scss';

.pageWrapper {
  width: 100%;
  height: 100vh;
  background: rgb(12,208,223);
  background: linear-gradient(90deg, rgba(12,208,223,1) 0%, rgba(37,37,203,1) 100%); 
  display: flex;
  justify-content: center;
  align-items: center;

  @at-root .refershPasswordWrapper {
    width: 400px;
    height: 550px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.31);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.3px);
    -webkit-backdrop-filter: blur(3.3px);
    border: 1px solid rgba(255, 255, 255, 0.41);

 

    @at-root .logoWrapper {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
      &>img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      
    }

    @at-root .formWrapper {
      position: relative;

      @at-root .inputBox {
        height: 36px;

        :global(.formControl-module__formLabel){
          background-color: transparent;
        }
        :global(.formControl-module__isFocusedd .formControl-module__formLabel){
          top: -10px !important;
          left: 0 !important;
        } 
      }

      @at-root .code {
        margin-top: 3em;
      }

      @at-root .password {
        margin-top: 4em;
      }

      @at-root .confirmPassword {
        margin-top: 2em;
      }

      @at-root .refreshBtnBox {
        margin-top: 3em;
        width: 100%;

        @at-root .refreshBtn {
          width: 100%;
          height: 40px;
        }
      }

      @at-root .backBtnBox {
        margin-top: 0.5em;
        width: 100%;
      }

      @at-root .backBtn {
        width: 100%;
        height: 40px;
      }
    }
  }
}



 