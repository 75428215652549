@import '../../sass\ configs/00-complete';

.buttonA {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    min-height: 32px;
    line-height: 1.57em;     //22px
    padding: 0.285em 1.071em;    //4px 15px
    cursor: pointer;
     text-transform: capitalize;
}

.primary {
    background: #1890FF;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    color: #FFFFFF;
    transition: all .5s;
    border-radius: 10px;
    padding: 10px;
   
    &:hover {
      background-color: rgba(0, 0, 0, 0.76);
      color: #fff;

    }
}

.secondary {
    background: lightgreen;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.5s;
    padding: 10px; 
    &:hover {
      background-color: #1890FF;
      color: #fff;
    }

}

.terthiary {
   background: rgba(255, 0, 0, 0.849);
    border: 1px solid rgba(255, 0, 0, 0.849);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    color: #FFFFFF;
    transition: all .5s;
    border-radius: 10px;
    padding: 10px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.76);
      color: #fff;
    }
}