@import '../../../sass\ configs/00-complete.scss';

//container, filterBox, filter, filterItem, filterLabel, filterValue, userListTable, headRow, bodyRow, search, role, status, createdRange



.rolePolicyEditTable {
    font-size: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;


    @at-root .confirmBtn {
        margin-top: 2em;
        display: flex;
        justify-content: flex-end;
    }

    @at-root .tableContainer {
        font-size: 10px;
        margin-top: 4em;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;


        @at-root .table {
            min-width: 100%;
            border-collapse: collapse;
            white-space: nowrap;
    
            @at-root thead {
                @at-root .headRow {       
                    & > td {
                        font-size: 1.4em;    //14px     1.4em
                        border: 1px solid gray;
                        font-family: 'Roboto', sans-serif;
                        height: 4em;        //56px     4em
                        text-align: center;
                        background: #FAFAFA;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        padding: 0 0.71em;     //0 10px      0 0.71em
                    }        
                }
            }
            @at-root tbody {
                @at-root .bodyRow {
                    & > td {
                        font-size: 14px;       //14px     1.4em
                        font-family: 'Roboto', sans-serif;
                        text-align: center;
                        border: 1px solid gray;
                        background: #FFFFFF;
                        height: 4em;        //56px     4em
                        border: 1px solid rgba(0, 0, 0, 0.06); 
                        padding: 0 0.71em;     //0 10px      0 0.71em
    
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 20ch;
                    }

                    @at-root .changed {
                        background-color: $changed-cell-color !important;
                    }
                    @at-root .confirmed {
                        background-color: $confirmed-cell-color !important;
                    }
                }
            }
        }
    }
        
       
    
}




