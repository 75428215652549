@import '../../sass\ configs/00-complete.scss';

.formWrapper {
  position: relative;

  @at-root .inputBox {
    height: 36px;

    :global(.formControl-module__formLabel){
      background-color: transparent;
    }
    :global(.formControl-module__isFocusedd .formControl-module__formLabel){
      top: -10px !important;
      left: 0 !important;
    } 
  }


  @at-root .email {
    margin-top: 1em;
  }

  @at-root .password {
    margin-top: 2em;
  }

  @at-root .confirmPassword {
    margin-top: 2em;
  }

  @at-root .role {
    margin-top: 2em;
  }
  @at-root .confirmBtnBox {
    margin-top: 2em;
    width: 100%;

    @at-root .confirmBtn {
      width: 100%;
      height: 40px;
    }
  }
}


