// addCharsFormControl,main,main_text,main_icon,options_spot

@import '../../../sass\ configs/00-complete.scss';


.addObjectsFormControl {
   height: 100%;
   width: 100%;
   position: relative;

   @at-root .focused .main {
        border-color: #c9a220;
        outline-color: #c9a220;
   }
    @at-root .main {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #6B451B;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 19px;
        border-color: #C9A220;
        outline-color: #C9A220;
        border: 1px solid #C9A220;
        outline: 1px solid transparent;
        padding-left: 11px;
        padding-right: 36px;
        border-radius: 4px;
        

        @at-root .main_text {
            display: flex;
            width: 100%;

            @at-root .labelItem {
                box-shadow: 0 2px 2px rgba(0,0,0,0.2);
                padding: 0.2em 1.4em 0.2em 1em;
                border: 1px solid gray;
                border-radius: 4px;
                position: relative;
                height: fit-content;
                span {
                    position: absolute;
                    display: inline-block;
                    margin-left: 0.1em;
                    top: -0.1em;
                    right: 0;
                    cursor: pointer;
                }

                &:not(:last-child){
                    margin-right: 1em;
                }
            }
        }

        @at-root .main_icon {

        }
    }

    @at-root .options_spot {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 1em;
        max-height: 200px;
        overflow-y: scroll;
        background-color: white;
        border-color: #C9A220;
        outline-color: #C9A220;
        border: 1px solid #C9A220;
        outline: 1px solid transparent;
    }
}