@import '../../sass\ configs/00-complete.scss';

$font-family: 'Roboto', sans-serif;


.mobilePagination {
    font-size: 10px;
    direction: ltr;
    display: flex;
    justify-content: center;
    align-items: center;
    

    @at-root .item {
        border-radius: 4px;
        height: 2.8em;    //28px    2.8em
        background-color: #FEF4D4;

        &:not(:last-child){
            margin-right: 0.8em;     //8px     0.8em
        }
    }

    @at-root .first {
        width: 2.8em;    //28px   2.8em
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        &::after {
            content: '';
            position: relative;
            width: 1.811em;     //18.11px   1.811em
            height: 1.8em;       //18px     1.8em
            background-image: url('../../assets//images//right5x2.svg');
            background-size: cover;
            background-repeat: no-repeat;
            transform: rotateY(180deg);
        }
    }

    @at-root .next {
        width: 2.8em;    //28px   2.8em
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        &::after {
            content: '';
            position: relative;
            width: 1.111em;     //11.11px   1.111em
            height: 1.8em;       //18px     1.8em
            background-image: url('../../assets//images//right5.svg');
            background-size: cover;
            background-repeat: no-repeat;
            
        }
    }

    @at-root .current {
        //@include typo($font-family: IranYekanMobileMedium,$font-size: 14px,$line-height: 15px,$color: #6B451B);
        @include typo($font-family: $font-family,$font-size: 1.4em,$line-height: 1.071em,$color: #6B451B);
        width: 5.07em;     //71px    5.07em
        display: flex;
        justify-content: center;
        align-items: center;

        & > span:nth-child(2){
            margin: 0 0.285em;    //0 4px      0 0.285em
        }
    }

    @at-root .last {
        width: 2.8em;    //28px   2.8em
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        &::after {
            content: '';
            position: relative;
            width: 1.811em;     //18.11px   1.811em
            height: 1.8em;       //18px     1.8em
            background-image: url('../../assets//images//right5x2.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    @at-root .prev {
        width: 2.8em;    //28px   2.8em
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        &::after {
            content: '';
            position: relative;
            width: 1.111em;     //11.11px   1.111em
            height: 1.8em;       //18px     1.8em
            background-image: url('../../assets//images//right5.svg');
            background-size: cover;
            background-repeat: no-repeat;
            transform: rotateY(180deg);
        }
    }

    @at-root .clickable {
        cursor: pointer;

        &:hover {
            box-shadow: 0 2px 4px rgba(0,0,0,0.16);
        }
    }
   
}