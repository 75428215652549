.addItemFormControl {
    font-size: 10px;

    @at-root .confirmBtn {
        margin-top: 0.5em;
        margin-right: auto;
        width: 10em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 4px rgba(0,0,0,0.16);
    }

    @at-root .panel {
        margin-top: 0.5em;
        height: 10em;      //100px    10em
        border-radius: 4px;
        overflow-x: hidden;
        padding: 1em;
        border: 1px solid #c9a220;;
        outline: 1px solid transparent;

        @at-root .err .panel {
            border: 1px solid #DC172A;
            outline: 1px solid #DC172A;
        }

        @at-root .item {
            z-index: 0;
            height: 3em;
            padding: 0 1em;
            box-shadow: 0 2px 4px rgba(0,0,0,0.16);
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:first-child){
                margin-top: 1em;
            }

            @at-root .itemTitle {
                
            }

            @at-root .itemRemoveBtn {
                display: block;
                width: 2em;
                height: 2em;
                border-radius: 50%;
                background-color: pink;
                box-shadow: 0 2px 4px rgba(0,0,0,0.16);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}