@import '../../sass\ configs/00-complete.scss';

$font-family: 'Roboto', sans-serif;

.formControl {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    border: 1px solid #C9A220;
    outline: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: white;

    &.textarea {

    }

    /* styles for showing fake placeholder when input is empty */
    &::after {
        position: absolute;
        display: block;
        content: attr(data-placeholder);
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        left: 16px;   /**/
        z-index: -1;
        font-family: $font-family;
        font-size: 14px;
        line-height: 19px;
        color: gray;
    }

    &.isFocusedd.hasNotValue::after{
        opacity: 1;
        transition: opacity 1s ease-in-out 0.5s;
    }

    /* styles for showing fake Error msg when input is invalid */
    &.isNotValid::before {
        position: absolute;
        display: block;
        content: attr(data-errormsg);
        bottom: -1px;
        transform: translateY(100%);
        left: 0;    /**/
        z-index: 1;
        font-family: $font-family;
        font-size: 11px;
        line-height: 15px;
        color: #DC172A;
    }



    @at-root .formInput {
        border: none;
        width: 100%;
        height: 100%;
        padding-left: 11px;        /**/
        padding-right: 36px;   /**/
        // caret-color: #FDEAA9;
        position: relative;
        outline: none;
        white-space: pre;
        z-index: 0;
        font-family: $font-family;
        font-size: 14px;
        line-height: 19px;
        color: #6B451B;
        background-color: transparent;
        /* background-color: rgba(100,50,75,0.2); */
    }

    @at-root .formLabel {
        position: absolute;
        top: 50%;
        left: 11px;      /**/
        transform: translateY(-50%);
        font-family: $font-family;
        font-size: 14px;
        line-height: 19px;
        background-color: #ffffff;
        color: black;
        text-shadow: 0 2px 4px rgba(0,0,0,0.8);
        z-index: 0;
    }

    @at-root .formBtn {
        position: absolute;
        right: 16px;     /**/
        top: 50%;
        width: 16px;
        height: 16px;
        
        background-image: var(--icon);
        background-size: 100% 100% !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        transform: translateY(-50%);
        z-index: 3;

        @at-root .formControl:not(.isFilled) .formBtn {
            display: none !important;
        }
        @at-root .formControl .formBtn.passToggler {
            background-image: var(--icon) !important;
        }

        @at-root .formControl:hover .formBtn.passToggler {
            background-image: var(--icon-hover) !important;
        }

        @at-root .formControl .formBtn.passToggler.passVisible {
            background-image: var(--icon-hover) !important;
        }

        @at-root .formControl.isFilled .formBtn {
            background-image: url('../../assets/images/closeDefault.svg');
        }
    }


    /* styles for hover */
    &:hover {
        border-color: #FCCB29;

        &.formLabel {
            color: #6B451B;
        }

        @at-root .formControl.isFilled:hover  .formBtn{
            background: url('../../assets/images/closeHover.svg');   
        }

        @at-root .formControl:not(.isFilled):hover  .formBtn {
            background: var(--icon-hover);   
        }
    }


    /* styles for being focused and has been filled */
    &.isFocusedd {
        border-color: #C9A220;
        outline-color: #C9A220;
        /* margin: -1px;    a hack for preventing shake of element between states with different border width */
        /* border-width: 2px; */

        &:not(.isFilled) .formBtn {
            background: var(--icon-hover);
        }

        .formLabel {
            display: inline-block;
            top: 0;
            right: 21px;    /**/
            transform: translateY(-63.33%);
            z-index: 0;
            padding: 0 3px 0 3px;    /**/
            font-family: $font-family;
            font-size: 11px;
            line-height: 15px;
            transition: all 0.5s ease-in 0s;
        }
    }

    &.isFilled {

        .formLabel {
            display: inline-block;
            top: 0;
            right: 21px;    /**/
            transform: translateY(-63.33%);
            z-index: 0;
            padding: 0 3px 0 3px;    /**/
            font-family: $font-family;
            font-size: 11px;
            line-height: 15px;
            transition: all 0.5s ease-in 0s;
        }

        .formBtn {
            background: url('../../assets/images/closeHover.svg');
        }
    }

    /* styles for Error */
    &.isNotValid {
        border-color: #DC172A;
        outline-color: #DC172A;
        transition: all 1s ease-in-out;

        .formInput {
            color: #DC172A;
            transition: all 1s ease-in-out;
        }

        .formLabel {
            color: #DC172A;
            transition: all 1s ease-in-out;
        }

        .formBtn,
        &:hover .formBtn {
            background: url('../../assets/images/closeError.svg');
            transition: all 1s ease-in-out;
        }
    }



    &.textarea {
        &::after {
            display: none;
        }

        .formInput {
            padding-top: 10px;
            padding-bottom: 10px;
            resize: none;
        }

        .formBtn {
            display: none;
        }

        .formLabel{
            position: absolute;
            top: 0;
            right: 11px;     
            transform: translateY(50%);
            font-family: $font-family;
            font-size: 14px;
            line-height: 19px;
            background-color: #ffffff;
            color: #C9A220;
            z-index: -1;
        }

        &:hover {
            .formLabel {
                color: #6B451B;
            }
        }

        &.isFocusedd {
            .formLabel{
                display: inline-block;
                top: 0;
                right: 21px;    /**/
                transform: translateY(-63.33%);
                z-index: 4;
                padding: 0 3px 0 3px;    /**/
                font-family: $font-family;
                font-size: 11px;
                line-height: 15px;
                transition: all 0.5s ease-in 0s;
            }
        }

        &.isFilled {
            .formLabel{
                display: inline-block;
                top: 0;
                right: 21px;    /**/
                transform: translateY(-63.33%);
                z-index: 4;
                padding: 0 3px 0 3px;    /**/
                font-family: $font-family;
                font-size: 11px;
                line-height: 15px;
                transition: all 0.5s ease-in 0s;
            }
        }

        &.isNotValid {
            .formLabel {
                color: #DC172A;
                transition: all 1s ease-in-out;
            }
        }
    }



    &.select {
        
        :global(.myOwnSelect-module__select){
            

            :global(.myOwnSelect-module__absoluteOptionsContainer.myOwnSelect-module__optionContainerIsVisible){
                
            }

            :global(.myOwnSelect-module__absoluteOptionsContainer){
                font-size: 1.1em;
                line-height: 2em;
            }
        }

        @at-root .dropDown {
            // position: absolute;
            // display: block;
            // height: 13.7857%;
            // left: 16px;
            // top: 55%;
            // transform: translateY(-50%);
            // z-index: 1;
            display: none;
        }
    }
}
  
        
   
 



     




 