@import '../../sass\ configs/00-complete.scss';

.pageWrapper {
  width: 100%;
  height: 100vh;
  background: rgb(12,208,223);
  background: linear-gradient(90deg, rgba(12,208,223,1) 0%, rgba(37,37,203,1) 100%); 
  display: flex;
  justify-content: center;
  align-items: center;

  @at-root .loginWrapper {
    width: 400px;
    height: 550px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.31);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.3px);
    -webkit-backdrop-filter: blur(3.3px);
    border: 1px solid rgba(255, 255, 255, 0.41);

    @at-root .tabWrapper {
      display: flex;
      justify-content: space-between;
      font-family: 'Roboto';

      div {
        width: 50%;
        height: 40px;
        border-bottom: 1px solid gray;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;

        span {
          transition: all .2s;
          transform: translateY(0);
        }

        &:first-child {
          border-right: 1px solid gray;
        }
        
        &:hover {
          transition: all .3s;
          background: rgba(255, 255, 255, 0.12);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(1.8px);
          -webkit-backdrop-filter: blur(1.8px);
          border: 1px solid rgba(255, 255, 255, 0.41);
        }

        &:hover span {
       
        }
      }
    }

    @at-root .logoWrapper {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
      &>img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      
    }

    @at-root .formWrapper {
      position: relative;

      @at-root .inputBox {
        height: 36px;

        :global(.formControl-module__formLabel){
          background-color: transparent;
        }
        :global(.formControl-module__isFocusedd .formControl-module__formLabel){
          top: -10px !important;
          left: 0 !important;
        } 
      }

      @at-root .email {
        margin-top: 2em;
      }

      @at-root .password {
        margin-top: 2em;
      }

      @at-root .loginBtnBox {
        margin-top: 3em;
        width: 100%;

        @at-root .loginBtn {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}



 .forgetPass {
    color: #d4d4d8;
    cursor: pointer;
    transition: all .5s;
    padding-left: 10px;
    margin-top: 10px;
    &:hover {
      color: #fafafa;
      text-shadow: 0 2px 4px invert($color: rgb(72, 207, 101))
      // transform: translateY(-2px);
    }
  }
  .signUpWrapper {
    display: flex;
    justify-content: space-between;
  }
