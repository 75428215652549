@import '../../sass\ configs/00-complete.scss';


.groupLink {
    cursor: pointer;
    // box-shadow: 0px 2px 1px 2px rgba(0,0,0,0.16);
    background-color: transparent;
    
    
    &.activeGroup {
        background-color: rgba(0,255,0,0.05);
    }

    @at-root .head {
        display: flex;
        justify-content: space-between;
      color: #E6F7FF!important;
        align-items: center;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: 2em;
        padding-right: 1em;
        font-size: 1.5em;

        &:hover {
            background: lighten($color: (#1890FF), $amount: 4);
            
            box-shadow: inset -3px 0px 0px lighten($color: (#1890FF), $amount: 30);
        }

        @at-root .icon {

        }
        @at-root .title {

        }
    }
    @at-root .body {
        // border-top: 1px solid gray;
        
          max-height: 800px;
          overflow: hidden;
          //  animation: slide-in 1.5s ease-in-out;
    
        @at-root .link {
            font-size: 1.5em;
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            line-height: 1.7em;
            

            &:hover {
                background: lighten($color: (#1890FF), $amount: 4);
                box-shadow: inset -3px 0px 0px lighten($color: (#1890FF), $amount: 30);
            }

            & > a {
                text-decoration: none;
                display: block;
                width: 100%;
                color: white;
                padding-top: 1em;
                padding-bottom: 1em;
                padding-right: 1em;
                padding-left: 3em;
            }


            &.activeLink {
                box-shadow: inset -3px 0px 0px #1890FF;
                background: #E6F7FF;

                & > a {
                    color: #1890FF;
                }
            }
        }
    }
}
@keyframes slide-in {
  from {
    height:0;
  }

  to {
   height: 600px;
  }
}