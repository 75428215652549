.filter {
    display: grid;

    grid-template-areas: 'createdRange fromHour toHour media .  confirmBtn' ;
    grid-template-columns: repeat(7,auto);
    column-gap: 2em;
    row-gap: 1em;
    padding: 24px 28px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;

    @at-root .filterItem {
        display: flex;
        align-items: center;
        justify-content: stretch;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        @at-root .filterLabel {
            text-transform: capitalize;
            margin-right: 0.6em;
            color: rgba(0, 0, 0, 0.6);
        }
        @at-root .filterValue {

        }
    }

    @at-root .search {
        grid-area: search;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .status {
        grid-area: status;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }

    @at-root .role {
        grid-area: rolee;

        
        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
      @at-root .fromHour {
        grid-area: fromHour;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
        @at-root .toHour {
        grid-area: toHour;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
          @at-root .mediaServerA {
        grid-area: mediaServerA;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
           @at-root .mediaServerB {
        grid-area: mediaServerB;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
         @at-root .media {
        grid-area: media;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
    @at-root .createdRange {
        grid-area: createdRange;
        

        & .filterValue {
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 12px;
        
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 2px;

         

            @at-root .dateBox {
                height: 100%;
                align-self: center;

                :global(.react-datepicker-wrapper){
                    height: 100%;

                    :global(.react-datepicker__input-container) {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        input {
                            border: none;
                            height: 90%;
                            color: gray;
                        }
                    }
                    :global(.react-datepicker__triangle){
                        display: none !important;
                    }
                }

            }

            @at-root .arrIcon {
                margin: 0 10px;
            }
            @at-root .calIcon {
                margin-left: 10px;
            }
        }
    }

    @at-root .confirmBtn {
        grid-area: confirmBtn;
        margin-left: auto;
    }
}