@import '../../sass\ configs/00-complete.scss';




.userList {
    font-size: 10px;
    width: 100%;

    @at-root .container {
        padding: 3em 4em 4em;
        margin: 0 auto;



        @at-root .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            @at-root .itemLabel {
                color: rgba(0, 0, 0, 0.4);
                margin-right: 2em;

            }
            
            @at-root .itemValue {
                height: 32px;
                border: 1px solid darken(#D9D9D9,10);
                border-radius: 2px;
                width: 300px;
                padding: 4px 12px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}
