@import '../../sass\ configs/00-complete.scss';


// userCreate, container, item, itemLabel, itemInput, firstName, lastName, userName, phoneNumber, email, password, confirmPassword, role

.sendTopic {
    font-size: 10px;

    @at-root .filterSpot {
        :global(.filterE-module__filter){
            grid-template-areas: "pass topic qualityOfService"
                                        "retained type platformsAdd"
                                        "versionsAdd languagesAdd languagesAdd";
            grid-template-columns: repeat(3,auto);
            padding-bottom: 8em;
            row-gap: 2em;

        }
    }

    @at-root .body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        column-gap: 3em;
        row-gap: 3em;
        padding: 2em 2em;
        @at-root .languageSection {

            @at-root .langLabel {
                text-transform: capitalize;
                font-size: 2em;
            }
            @at-root .langInput {
                margin-top: 1em;
            }
        }
    }
}
