@import '../../sass\ configs/00-complete.scss';


// talkId,firstChars,
//         secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, 
//         createdRange, confirmBtn, dateBox, sort, ascending,
//         country, appVersion, search, status, platform, duration, internetQuality

.filter {
    display: grid;
    position: relative;
    z-index: 0;
    grid-template-areas: 'talkId accountId createdRange confirmBtn'
                                 'appVersion minDuration maxDuration platform'
                                 'firstChars status sort internetQuality'
                                 'ascending isReconnectedC lang country'
                                 'endReason finisherId versionsAdd platformsAdd'
                                 'pass topic qualityOfService type '
                                 '. . mediaDomain talkTypeCss '
                                 
                          
                                  ;

    grid-template-columns: repeat(3,auto);
    column-gap: 2em;
    row-gap: 2em;
    padding: 24px 28px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;

    @at-root .filterItem {
        display: flex;
        align-items: center;
        justify-content: stretch;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        @at-root .filterLabel {
            text-transform: capitalize;
            margin-right: 0.6em;
            color: rgba(0, 0, 0, 0.6);
            min-width: 16ch;
        }
        @at-root .filterValue {
            
        }
    }

    @at-root .talkId {
        grid-area: talkId;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .pass {
        grid-area: pass;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }
    @at-root .topic {
        grid-area: topic;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }
    @at-root .type {
        grid-area: type;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }
    @at-root .mediaDomain {
        grid-area: mediaDomain;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }
    @at-root .accountId {
        grid-area: accountId;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .country {
        grid-area: country;
        position: relative;
        z-index: 0;
     

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .minDuration {
        grid-area: minDuration;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }

    @at-root .maxDuration {
        grid-area: maxDuration;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
            width: 150px;
        }
    }
    @at-root .appVersion {
        grid-area: appVersion;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
            width: 100px;
        }
    }

    @at-root .search {
        grid-area: search;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .finisherId {
        grid-area: finisherId;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }


    @at-root .firstChars {
        grid-area: firstChars;
        position: relative;
        z-index: 2;

        & .filterValue {
            height: 32px;
         
        }
    }

    @at-root .platformsAdd {
        grid-area: platformsAdd;
        position: relative;
        z-index: 4;

        & .filterValue {
            height: 32px;
            width: 13em;
        
        }
    }

    @at-root .languagesAdd {
        grid-area: languagesAdd;
        display: inline-grid;
        position: relative;
        z-index: 4;
        align-self: start;
      
        .filterLabel {
            align-self: start;
        }

        & .filterValue {
            height: 6em;
            width: 18em;
            // max-height: 14em;
            :global(.addObjectFormControl-module__main){
                padding: 0 0.2em;
                height: 100%;
            }
            :global(.addObjectFormControl-module__main_text){
                height: 100%;
                // max-width: 10em;
                padding: 0;
                display: grid;
                align-items: start;
                grid-auto-flow: row;
                grid-template-columns: repeat(2, auto);
                grid-template-rows: min-content;
                row-gap: 0.2em;
                // row-gap: 0.1em;
                overflow-y: scroll;
                overflow-x: hidden;

            }
            :global(.addObjectFormControl-module__labelItem){
                width: 8em;
            }
        
        }
    }
    @at-root .versionsAdd {
        grid-area: versionsAdd;
        position: relative;
        z-index: 2;
        align-self: start;

        .filterLabel {
            align-self: flex-start;
        }
        

        & input {
            height: 32px;
        
        }
    }

    @at-root .status {
        grid-area: status;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .endReason {
        grid-area: endReason;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }
 
    @at-root .internetQuality {
        grid-area: internetQuality;
        position: relative;
        z-index: 2;

  

        & .filterValue {
            height: 32px;
            width: 200px;
         
        }
    }
     @at-root .talkTypeCss {
        grid-area: talkTypeCss;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
           }
    }
    @at-root .qualityOfService {
        grid-area: qualityOfService;
        position: relative;
        z-index: 5;

        & .filterValue {
            height: 32px;
            width: 200px;
         
        }
    }

    @at-root .sort {
        grid-area: sort;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .platform {
        grid-area: platform;
        position: relative;
        z-index: 2;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .ascending {
        grid-area: ascending;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .isReconnectedC {
        grid-area: isReconnectedC;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .retained {
        grid-area: retained;
        position: relative;
        z-index: 0;

        & .filterValue {
         
        }
    }

    @at-root .lang {
        grid-area: lang;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .secondChars {
        grid-area: secondChars;
        position: relative;
        z-index: 2;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .firstAccount {
        grid-area: firstAccount;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .secondAccount {
        grid-area: secondAccount;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .firstDevice {
        grid-area: firstDevice;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .secondDevice {
        grid-area: secondDevice;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .createdRange {
        grid-area: createdRange;
        position: relative;
        z-index: 4;
        

        & .filterValue {
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 12px;
        
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 2px;

         

            @at-root .dateBox {
                height: 100%;
                align-self: center;

                :global(.react-datepicker-wrapper){
                    height: 100%;

                    :global(.react-datepicker__input-container) {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        input {
                            border: none;
                            height: 90%;
                            color: gray;
                        }
                    }
                    :global(.react-datepicker__triangle){
                        display: none !important;
                    }
                }

            }

            @at-root .arrIcon {
                margin: 0 10px;
            }
            @at-root .calIcon {
                margin-left: 10px;
            }
        }
    }

    @at-root .confirmBtn {
        grid-area: confirmBtn;
        margin-left: auto;
    }
}