@import '../../sass\ configs/00-complete.scss';


// sectionLink, head, icon, title, body


.sectionLink {
    cursor: pointer;
    // box-shadow: 0px 2px 1px 2px rgba(42, 104, 238, 0.16);
    border-bottom: 1px solid rgba(255, 255, 255, 0.548);
    margin-bottom: 1rem;
      

    @at-root .head {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 1em;
        padding: 1em 1em;
        font-size: 1.5em;
        color: #E6F7FF;
        // border: 2px solid orange;
        // background-color: #1890FF;
  

        @at-root .title {
            // border: 1px solid purple;
          
        }
        @at-root .icon {
            
        }
    }

    @at-root .body {
    
       
    }

}

@at-root .linkItem {
    font-size: 1.5em;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 1.7em;
   

    &:hover {
        background: lighten($color: (#E6F7FF), $amount: 4);
        box-shadow: inset -3px 0px 0px lighten($color: (#1890FF), $amount: 30);
    }


    @at-root .activeItem {
        box-shadow: inset -3px 0px 0px #1890FF;
        background: #E6F7FF;
        

        & .link {
            color: #1890FF;
        }
    }

    @at-root .link {
        padding: 0.7em 3em;
        display: block;
        width: 100%;
        text-decoration: none;
        text-transform: capitalize;
        color: inherit;
    }
}
