 @at-root .container {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        column-gap: 1em;
        row-gap: 1em;
        padding-top: 2em;
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        
        

        @at-root .item {
          
            padding: 2em;
            background-color: rgb(0,0,0);
                background: rgb(17,24,39);
background: linear-gradient(98deg, rgba(17,24,39,1) 0%, rgba(44,61,99,1) 100%);
            border: 1px solid pink;
            font-family: "roboto";
            box-shadow: inset 0 2px 4px greenyellow;

            @at-root .itemLabel {
                font-size: 2em;
                text-transform: capitalize;
                text-align: center;
                padding-bottom: 0.8em;
                border-bottom: 1px solid darken($color: white, $amount: 7);
                color: white;
            }

            @at-root .itemValue { 
             
                font-size: 10em;
                // padding-top: 0.1em;
                margin-top: 0.2em;
                text-align: center;
                color: white;
                

            }
        }

     
    }