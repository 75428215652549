@import '../../sass\ configs/00-complete.scss';

.nav {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 4em 0.5em 2em;
    // background-color: $hamed-bg-first;
    background: rgb(17,24,39);
background: linear-gradient(98deg, rgba(17,24,39,1) 0%, rgba(44,61,99,1) 100%);

    @at-root .logoBox {
        width: 20em;  
    }

    @at-root .menuBox {
        min-width: 13em;
        display: flex;

    }
   
}
 .navigateBtn {
    color:white;
     padding: .5rem 1rem;
     margin: 0 3rem;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     border-radius: 2px;
     border: 1px solid white;;
     box-shadow: 0 0 2px 1px white;
     transition: all 0.3s;
     font-size: 17px;
     &:hover {
      background-color: yellow;
      color: black;
     }
      
    }