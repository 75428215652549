
$URL: '';
$IMGS_URL: '';


$colors: (
  GRAY: (
    50: #fafafa,
    100: #f4f4f5,
    200: #e4e4e7,
    300: #d4d4d8,
    400: #a1a1aa,
    500:  #71717a,
    600: #52525b,
    700: #3f3f46,
    800: #27272a,
    900: #18181b
  ),
  GRAY_COOL: (
    50: #f9fafb,
    100: #f3f4f6,
    200: #e5e7eb,
    300: #d1d5db,
    400: #9ca3af,
    500:  #6b7280,
    600: #4b5563,
    700: #374151,
    800: #1f2937,
    900: #111827
  ),
  GRAY_BLUE: (
    50: #f8fafc,
    100: #f1f5f9,
    200: #e2e8f0,
    300: #cbd5e1,
    400: #94a3b8,
    500:  #64748b,
    600: #475569,
    700: #334155,
    800: #1e293b,
    900: #0f172a
  ),
  RED: (
    50: #fef2f2,
    100: #fee2e2,
    200: #fecaca,
    300: #fca5a5,
    400: #f87171,
    500:  #ef4444,
    600: #dc2626,
    700: #b91c1c,
    800: #991b1b,
    900: #7f1d1d
  ),
  AMBER: (
    '50': #fffbeb,
    '100': #fef3c7,
    '200': #fde68a,
    '300': #fcd34d,
    '400': #fbbf24,
    '500':  #f59e0b,
    '600': #d97706,
    '700': #b45309,
    '800': #92400e,
    '900': #78350f
  ),
  EMERALD: (
    '50': #ecfdf5,
    '100': #d1fae5,
    '200': #a7f3d0,
    '300': #6ee7b7,
    '400':#34d399,
    '500': #10b981,
    '600': #059669,
    '700': #047857,
    '800': #065f46,
    '900': #064e3b
  ),
  BLUE_LIGHT: (
    '50': #f0f9ff,
    '100': #e0f2fe,
    '200': #bae6fd,
    '300': #7dd3fc,
    '400':#38bdf8,
    '500': #0ea5e9,
    '600': #0284c7,
    '700': #0369a1,
    '800': #075985,
    '900': #0c4a6e
  ),
  BLUE: (
    '50': #eff6ff,
    '100': #dbeafe,
    '200': #bfdbfe,
    '300': #93c5fd,
    '400':#60a5fa,
    '500': #3b82f6,
    '600': #2563eb,
    '700': #1d4ed8,
    '800': #1e40af,
    '900': #1e3a8a
  ),
  ROSE: (
    '50': #fff1f2,
    '100': #ffe4e6,
    '200': #fecdd3,
    '300': #fda4af,
    '400':#fb7185,
    '500': #f43f5e,
    '600': #e11d48,
    '700': #be123c,
    '800': #9f1239,
    '900': #881337
  ),

  


);



@function color($base, $shade: base) {
    $color: map-get(map-get($colors, $base), $shade);
    @return $color;
}

//usage
// h1 {
//     color: color(course);
// }


$hamed-bg-first: #111827;   //dark-gray
$hamed-bg-second: #e11d48;
$hamed-bg-third: map-get(map-get($colors, RED), '100');
$hamed-bg-fourth: map-get(map-get($colors, RED), '100');
$hamed-bg-fifth: map-get(map-get($colors, RED), '100');
$hamed-bg-sixth: map-get(map-get($colors, RED), '100');
$hamed-bg-seventh: map-get(map-get($colors, RED), '100');



$hamed-cl-first: #111827;   //dark-gray
$hamed-cl-second: map-get(map-get($colors, RED), '100');
$hamed-cl-third: map-get(map-get($colors, RED), '100');
$hamed-cl-fourth: map-get(map-get($colors, RED), '100');
$hamed-cl-fifth: map-get(map-get($colors, RED), '100');
$hamed-cl-sixth: map-get(map-get($colors, RED), '100');
$hamed-cl-seventh: map-get(map-get($colors, RED), '100');

$changed-cell-color: #ffe4e6;
$confirmed-cell-color: #d1fae5;