@import '../../sass\ configs/00-complete.scss';

.check {
    
}
    .input {
        display: none;
    }
    .label {
        
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
    }
    .fakeInput {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #6B451B;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: gray;
        border-radius: 4px;
        background-color: white;
    }
.input:checked ~ .label > .fakeInput {
    background-image: url('../../assets//images//tick.png');
    background-size: cover;
    border-color: #1e2836;
    border-radius: 4px;
}

   
   