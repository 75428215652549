@import '../../sass\ configs/00-complete.scss';


$font-family: 'Roboto', sans-serif;

.policyEdit {
    font-size: 10px;

    @at-root .container {
        padding: 3em 2em 4em;



       

    
 
    }
}
