@import '../../../sass\ configs/00-complete.scss';


.dailyDurationReportTable {
    min-width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    @at-root thead {
        @at-root .headRow {       
            & > td {
                font-size: 1.4em;    //14px     1.4em
                border: 1px solid gray;
                font-family: 'Roboto', sans-serif;
                height: 4em;        //56px     4em
                text-align: center;
                background: #FAFAFA;
                border: 1px solid rgba(0, 0, 0, 0.06);
                padding: 0 0.71em;     //0 10px      0 0.71em
            }        
        }
    }
    @at-root tbody {
        @at-root .bodyRow {
            &:not(:last-child){
                border-bottom: 2px solid black;
            }
            &:nth-child(even){
                > * {
                    background-color: lighten($color: red, $amount: 45);
                }
            }
            &:nth-child(odd) {
                > * {
                    background-color: lighten($color: green, $amount: 70);
                }
            }
            & > td {
                font-size: 14px;       //14px     1.4em
                font-family: 'Roboto', sans-serif;
                text-align: center;
                border: 1px solid gray;
                background: #FFFFFF;
                height: 4em;        //56px     4em
                border: 1px solid rgba(0, 0, 0, 0.06); 
                padding: 0 0.71em;     //0 10px      0 0.71em

                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 20ch;
            }
            @at-root .pair {
                padding: 0 !important;
              

                 @at-root .single {
                    height: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                     &:first-child {
                        border-bottom: 1px solid gray;
                     }
                 }
             }
        }
    }
}


 

          

