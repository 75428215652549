@at-root .userEditStatus {
    font-size: 10px;
    padding: 3em 0 4em;
    margin: 0 auto;
    display: grid;
    grid-template-areas: 'rolee . confirmBtn';
    grid-template-columns: auto 10em auto;
    justify-content: center;
    column-gap: 2em;
    row-gap: 2em;

    @at-root .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        @at-root .itemLabel {
            color: rgba(0, 0, 0, 0.4);
            margin-right: 2em;
        }
            
        @at-root .itemInput {
            height: 32px; 
            width: 300px;
        }
    }

    @at-root .status {
        grid-area: rolee;
    }

    @at-root .confirmBtn {
        grid-area: confirmBtn;
    }
 
}

