@import '../../sass\ configs/00-complete.scss';




.countryTalkListC {
    font-size: 10px;
    width: 100%;

    @at-root .container {
        padding: 3em 4em 4em;
        margin: 0 auto;

        @at-root .filterSpot {
            :global(.filterE-module__filter){
                grid-template-areas: "createdRange confirmBtn"
                                            "sort .";
                grid-template-columns: 1fr auto;
            }        
        }

        @at-root .body {
        
            margin-top: 2em;
            
            :global(.tabs-module__tabList){
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
                list-style: none;
            
                :global(.tabs-module__tabItem){
                    // @include typo($font-family: 'Roboto',$font-size: 16px,$line-height: 24px,$color: #1890FF);
                    @include typo($font-family: 'Roboto',$font-size: 1.6em,$line-height: 1.5em,$color: rgba(0, 0, 0, 0.85));
                    padding-bottom: 0.71em;     //13.5px      0.71em
                    margin-bottom: -2px;
                    text-transform: capitalize;
                    margin-right: 1em;
            
                    &:not(:first-child){
                        margin-right: 1.68em;     //32px     1.68em
                    }
                }
                :global(.tabs-module__tabActive){
                    border-bottom: 2px solid #1890FF;
                    color: #1890FF;
                    
                }
            }
            :global(.tabs-module__tabsContentWrapper){
                
                :global(.tabs-module__tabContent){
                    display: none;
                }
                :global(.tabs-module__contentActive){
                    display: block;
                }
             
            }
        }    

        
    }
}
