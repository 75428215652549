@import '../../sass\ configs/00-complete.scss';

$font-family: 'Roboto', sans-serif;


.select {
    font-size: 10px;
    position: relative;
    width: 100%;
    height: 100%;
  
    @at-root .row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @at-root .selected {
            flex: 1;
            text-align: center;
            //@include typo($font-family: IranYekanFnBold,$font-size: 9px,$line-height: 16px,$color: #6B451B);
            @include typo($font-family: $font-family,$font-size: 1.2em,$line-height: 1.77em,$color: #6B451B);
        }
        @at-root .icon {
            margin-right: 1.11em;

        }
    }
    @at-root .absoluteOptionsContainer {
        //@include typo($font-family: IranYekanFnBold,$font-size: 9px,$line-height: 16px,$color: #6B451B);
        @include typo($font-family: $font-family,$font-size: 0.9em,$line-height: 1.77em,$color: #6B451B);
        position: absolute;
        background-color: #f5f5f5;
        max-height: 100px;
        overflow-y: scroll;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid rgba(128,128,128,0.2);
        display: none;

        &.optionContainerIsVisible {
            display: block;
        }
        @at-root .option{
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
