@import '../../../sass\ configs/00-complete.scss';

 @at-root .body {
            margin-top: 5em;

            :global(.tabs-module__tabList){
              margin-left: 2rem;
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
                list-style: none;
            
                :global(.tabs-module__tabItem){
                    // @include typo($font-family: 'Roboto',$font-size: 16px,$line-height: 24px,$color: #1890FF);
                    @include typo($font-family: 'Roboto',$font-size: 1.6em,$line-height: 1.5em,$color: rgba(0, 0, 0, 0.85));
                    padding-bottom: 0.71em;     //13.5px      0.71em
                    margin-bottom: -2px;
                    text-transform: capitalize;
                    margin-right: 1em;
            
                    &:not(:first-child){
                        margin-right: 1.68em;     //32px     1.68em
                    }
                }
                :global(.tabs-module__tabActive){
                    border-bottom: 2px solid #1890FF;
                    color: #1890FF;
                    
                }
            }
            :global(.tabs-module__tabsContentWrapper){
                margin-top: 3em;
                :global(.tabs-module__tabContent){
                    display: none;
                }
                :global(.tabs-module__contentActive){
                    display: block;
                }
             
            }
        }

.dashboard {
    font-size: 10px;

    @at-root .filterSpot {

        :global(.filterD-module__filterD){
            grid-template-areas: 
                                'refreshTime . confirmBtn'
                                'refreshTime . confirmBtn'
                                'fromHour toHour .'
                                'fromMin toMin .';
            grid-template-columns: repeat(3,auto);
            row-gap: 2em;
        }
        :global(.filterD-module__refreshTime){
            grid-area: refreshTime;
        }
        :global(.filterD-module__createdRange){
            z-index: 3;
        }
    }


    @at-root .container {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        column-gap: 1em;
        row-gap: 1em;
        padding-top: 2em;
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        

        @at-root .item {
       
            padding: 2em;
            background-color: rgb(0,0,0);
            border: 1px solid pink;
            font-family: "roboto";
            box-shadow: inset 0 2px 4px greenyellow;

            @at-root .itemLabel {
                font-size: 2em;
                text-transform: capitalize;
                text-align: center;
                padding-bottom: 0.8em;
                border-bottom: 1px solid darken($color: lightpink, $amount: 7);
                color: white;
            }

            @at-root .itemValue { 
                font-size: 10em;
                // padding-top: 0.1em;
                margin-top: 0.2em;
                text-align: center;
                color: white;
                

            }
        }

        
    }
}