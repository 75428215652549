@import '../../../sass\ configs/00-complete.scss';

//container, filterBox, filter, filterItem, filterLabel, filterValue, userListTable, headRow, bodyRow, search, role, status, createdRange



.userListTable {
    font-size: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    @at-root .filterBox {
        
    }

    @at-root .tableBox {
        margin-top: 3em;
        width: 100%;
        overflow-x: scroll;
        
        @at-root .userTable {
            min-width: 100%;
            border-collapse: collapse;
            white-space: nowrap;

            @at-root thead {
                @at-root .headRow {       
                    & > td {
                         font-size: 1.4em;    //14px     1.4em
                        font-family: 'Roboto', sans-serif;
                        height: 4em;        //56px     4em
                        text-align: center;
                        background: #2f8ee7;
                        border: 1px solid black;
                        padding: 0 0.71em;     //0 10px      0 0.71em
                        color: #fff;
                        font-weight: 900;
                    }        
                }
            }
            @at-root tbody {
                @at-root .bodyRow {
                    & > td {
                        font-size: 14px;       //14px     1.4em
                        font-family: 'Roboto', sans-serif;
                        text-align: center;
                        border: 1px solid gray;
                        background: #FFFFFF;
                        height: 4em;        //56px     4em
                        border: 1px solid rgba(0, 0, 0, 0.342); 
                        padding: 0 0.71em;     //0 10px      0 0.71em

                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 20ch;
                    }
                }
            }
        }
       
    
    }
    @at-root .exportBtn * {
        text-decoration: none !important;
    }
    @at-root .paginationBox {
        margin-top: 2em;
    }
}


