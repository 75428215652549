@import '../../../sass\ configs/00-complete.scss';

// languageDuration, head, body
.languageCount {
    font-size: 10px;

    @at-root .head {
        font-size: 2em;
        font-family: 'Roboto', sans-serif;
        text-shadow: 0 2px 4px rgba(107, 45, 214, 0.8);
    }
    @at-root .body {
        margin-top: 2em;
    }
}
