
.loaderBox
{
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animateBg 10s linear infinite;
  width: 15em;      //150px    15em
  height: 15em;     //150px    15em

  @at-root .loader {
    position: relative;
    width: 100%;
    height: 100%;

    & > span {
        position: absolute;
        top: 0;
        Left: 0;
        width: 100%;
        height: 100%;
        transform: rotate(calc(18deg * var(--i)));
        transform-origin: center center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1.5em;     //15px    1.5em
            height: 1.5em;       //15px    1.5em
            border-radius: 50%;
            background: #00ff0a;
            box-shadow: 0 0 10px #00ff0a,
                        0 0 20px #00ff0a,
                        0 0 40px #00ff0a,
                        0 0 60px #00ff0a,
                        0 0 80px #00ff0a,
                        0 0 100px #00ff0a;
            animation: animate 2s linear infinite;
            animation-delay: calc(0.1s * var(--i));
        }
    }
  }
}




@keyframes animateBg {
  0%
  {
    filter: hue-rotate(0deg);
  }
  100%
  {
    filter: hue-rotate(360deg);
  }
}



@keyframes animate {
  0%
  {
    transform: scale(1);
  }
  80%,100%
  {
    transform: scale(0);
  }
}