@import '../../../sass\ configs/00-complete.scss';



.newTalkListTable {
    font-size: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
      :global(.tabs-module__tabList){
              margin-left: 2rem;
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
                list-style: none;
               
            
                :global(.tabs-module__tabItem){
                    // @include typo($font-family: 'Roboto',$font-size: 16px,$line-height: 24px,$color: #1890FF);
                    @include typo($font-family: 'Roboto',$font-size: 1.6em,$line-height: 1.5em,$color: rgba(0, 0, 0, 0.85));
                    padding-bottom: 0.71em;     //13.5px      0.71em
                    margin-bottom: -2px;
                    text-transform: capitalize;
                    margin-right: 1em;
            
                    &:not(:first-child){
                        margin-right: 1.68em;     //32px     1.68em
                    }
                }
                :global(.tabs-module__tabActive){
                    border-bottom: 2px solid #1890FF;
                    color: #1890FF;
                    
                }
            }
            :global(.tabs-module__tabsContentWrapper){
                margin-top: 3em;
                :global(.tabs-module__tabContent){
                    display: none;
                }
                :global(.tabs-module__contentActive){
                    display: block;
                }
             
            }

    @at-root .filterBox {
        
    }

    @at-root .tableBox {
        margin-top: 3em;
        width: 100%;
        overflow-x: scroll;
        
        @at-root .userTable {
            min-width: 100%;
            border-collapse: collapse;
            white-space: nowrap;
            
            @at-root thead {
                @at-root .headRow {       
                    & > td {
                        font-size: 1.2em;    //14px     1.4em
                        font-family: 'Roboto', sans-serif;
                        height: 4em;        //56px     4em
                        text-align: center;
                        background: #2f8ee7;
                        border: 2px solid black;
                        padding: 0 0.71em;     //0 10px      0 0.71em
                        color: #fff;
                        font-weight: 700;
                       
                    }        
                }
            }
            @at-root tbody {
                @at-root .bodyRow {
                    border-bottom: 2px solid black;
                    &.redCss{
                        > * {
                            background-color: rgb(245, 102, 92);
                        }
                    }
                    &.greenCss {
                        > * {
                            background-color: lighten($color: rgb(81, 241, 81), $amount: 0);
                        }
                    }
                    &.blueCss{
                        > * {
                            background-color: lighten($color: rgb(42, 238, 228), $amount: 0);
                        }
                    }
                    &.yellowCss{
                        > * {
                            background-color: lighten($color: rgb(225, 245, 3), $amount: 0);
                        }
                    }
                     &.grayCss{
                        > * {
                            background-color: lighten($color: rgb(85, 85, 85), $amount: 30);
                        }
                    }
                     &.purpleCss{
                        > * {
                            background-color: lighten($color: rgb(172, 15, 235), $amount: 30);
                        }
                    }
                     &.brownCss{
                        > * {
                            background-color: lighten($color: rgb(87, 2, 2), $amount: 30);
                        }
                    }
                      &.orangCss{
                        > * {
                          background: rgb(17,227,227);
                          background: linear-gradient(0deg, rgba(17,227,227,1) 14%, rgba(255,255,255,1) 100%);
                        }
                    }
                      &.grGreenBlueCss{
                        > * {
                         background: rgb(17,78,227);
                        background: linear-gradient(0deg, rgba(17,78,227,1) 11%, rgba(38,223,88,1) 91%);
                        }
                    }
                       &.grBlueWhiteCss{
                        > * {
                          background: rgb(236,236,236);
                          background: linear-gradient(0deg, rgba(236,236,236,1) 11%, rgba(41,38,223,1) 91%); 
                        }
                    }
                       &.grpurpleCss{
                        > * {
                         background: rgb(227,17,152);
                        background: linear-gradient(0deg, rgba(227,17,152,1) 14%, rgba(255,255,255,1) 100%); 
                        }
                    }
                        &.grGreenWhiteCss{
                        > * {
                          background: rgb(13,244,44);
                          background: linear-gradient(0deg, rgba(13,244,44,1) 11%, rgba(233,233,238,1) 91%);   
                        }
                    }
                    & > td {
                        font-size: 13.3px;       //14px     1.4em
                        font-family: 'Roboto', sans-serif;
                        text-align: center;
                        border: 1px solid black;
                        background: #FFFFFF;
                        height: 5em;        //56px     4em
                        border: 1px solid rgba(0, 0, 0, 0.877); 
                        padding: 0 0.71em;     //0 10px      0 0.71em
                        
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 30ch;

                        

                        @at-root .pair {
                           padding: 0 !important;
                         

                            @at-root .single {
                               height: 50%;
                               display: flex;
                               justify-content: center;
                               align-items: center;
                                &:first-child {
                                    border-bottom: 1px solid black;
                                }
                            }
                        }

                    }
                }
            }
        }
       
    
    }
    @at-root .exportBtn * {
        text-decoration: none !important;
        display: inline-block;
    }
    @at-root .paginationBox {
        margin-top: 2em;
    }
}


