@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
//font-family: 'Roboto', sans-serif;

*,*::after,*::before {
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    margin: 0;
}



